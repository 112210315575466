import {NgModule} from '@angular/core';
import {SpotlightAIComponent} from './spotlightAI.component';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {ModalModule} from '../../theme/shared/components';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ScreenLoadingModule} from '../screenLoading/screenLoading.module';

@NgModule({
    declarations: [SpotlightAIComponent],
    exports: [SpotlightAIComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        ModalModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        ScreenLoadingModule,
    ],
})
export class SpotlightAIModule {
}
