import {
    AddAttachmentUsingPostPayload,
    BaseRequest,
    BaseRequestActivityScoreRequest,
    BaseRequestContactsAddRelatedRequest,
    BaseRequestContactUpdateRequest,
    BaseRequestMessageRequest,
    BaseRequestOfActivityRequest,
    BaseRequestOfContentAlertCommentRequest,
    BaseRequestOfCycleRequest,
    BaseRequestOfCycleUpdateRequest,
    BaseRequestOfScoreLoadDeleteResquest,
    BaseRequestOfScoreLoadResquest,
    BaseRequestSpaceContactsRequest,
    BaseResponse,
    ConfirmPasswordReset,
    ContactDisableRequest,
    ContentAlertCommentRequest,
    CreateRole,
    CreateUsingPost1Payload,
    CycleUpdateRequest,
    PaymentRequest,
    ResetPasswordRequest,
    ScoreLoadDeleteResquest,
    ScoreLoadResquest, SuggestionRequest,
    UpdateContactAvatarUsingPutPayload,
    UpdatePasswordUserRequest,
    UpdateRole,
    UpdateUserRequest,
    UpdateUserRoleRequest,
} from './../client/data-contracts';
import {Api} from '../client/Api';
import {Session} from '../model/Session';
import {StorageService} from './storage.service';
import {
    ContactResponse,
    ContactsRequest,
    ContactUpdateRequest,
    SpaceRequest,
    SpaceRequestUpdate,
    UpdateAvatarUsingPutPayload
} from '../client/data-contracts';
import {Observable, Subject} from 'rxjs';
import {SpaceNodeFlat} from '../../util/space';
import {PAGE_SIZE} from '../../api/utils/Constants';
import {ContentType, RequestParams} from '../client/http-client';

export class Service extends Api {

    app = 'app';
    size: number = null;

    private readonly spaces = new Subject<SpaceNodeFlat>();

    constructor(
        public storageService: StorageService
    ) {
        super(storageService);
    }


    // @ts-ignore
    // tslint:disable-next-line:max-line-length

    getAPITags = (headers?: Record<string, string>, params: RequestParams = {}) =>
        this.request<BaseResponse, void>({
            path: `/Api/V1/Tags`,
            method: "GET",
            secure: true,
            headers: headers,
            ...params,
        })

    async getTags(): Promise<Array<any>> {
        const schoolId = this.storageService.getCurrentSession().school.id;
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.getAPITags(
                    {
                        "Authorization": this.storageService.getCurrentToken(),
                        "schoolId": schoolId
                    }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject(reason);
                });
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }


    getAPISettingSchool = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
        this.request<BaseResponse, void>({
            path: `/Api/V1/Schools/${id}`,
            method: "GET",
            secure: true,
            headers: headers,
            ...params,
    })

    async getSettingSchool(userId: string): Promise<Array<any>> {
        const schoolId = this.storageService.getCurrentSession().school.id;
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.getAPISettingSchool(userId,
                    {
                        "Authorization": this.storageService.getCurrentToken(),
                        "schoolId": schoolId
                    }).then(value => {
                    // @ts-ignore
                        resolve(value.data['data']);
                }).catch(reason => {
                    reject(reason);
                });
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

    getAPICourse = (id: string, tags?, filters?, assigneeUserId? ,  headers?: Record<string, string>, params: RequestParams = {}) =>
        this.request<BaseResponse, void>({
            path: `/Api/V1/Spaces?parentId=${id}${tags ? `&tags=${tags}` : '' }${filters} ${assigneeUserId && `&assigneeUserId=${assigneeUserId}`}`,
            method: "GET",
            secure: true,
            headers: headers,
            ...params,
    })

    async getCourse(userId: string, tags?, filters?, assigneeUserId?, searchTerm?): Promise<Array<any>> {
        const schoolId = this.storageService.getCurrentSession().school.id;

        let makeFilter = '';

        if (filters){
            makeFilter = `${filters[0].active === 2 ? '&hasStudents=false' : filters[0].active === 1 ? '&hasStudents=true' : ''}${filters[1].active === 2 ? '&hasStaff=false' : filters[1].active === 1 ? '&hasStaff=true' : ''}${filters[2].active === 2 ? '&hasContentAlerts=false' : filters[2].active === 1 ? '&hasContentAlerts=true' : ''}${filters[3].active === 2 ? '&hasActivity=false' : filters[3].active === 1 ? '&hasActivity=true' : ''}`;
        }

        if (searchTerm){
            makeFilter = makeFilter ? `${makeFilter}&searchTerm=${searchTerm}` : `&searchTerm=${searchTerm}`;
        }

        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.getAPICourse(userId, tags, makeFilter, assigneeUserId,
                    {
                        "Authorization": this.storageService.getCurrentToken(),
                        "schoolId": schoolId
                    }).then(value => {
                    // @ts-ignore
                        resolve(value.data['data']);
                }).catch(reason => {
                    reject(reason);
                });
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    settingSchoolPut = (id: string, request: BaseRequestContactUpdateRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
        this.request<BaseResponse, void>({
            path: `/Api/V1/Schools/${id}`,
            method: "PUT",
            body: request,
            secure: true,
            type: ContentType.Json,
            format: "json",
            headers: headers,
            ...params,
        });

    // @ts-ignore
    async updateSettingSchool(userId: string, data): Promise<Array<any>> {
        const schoolId = this.storageService.getCurrentSession().school.id;
        await this.settingSchoolPut(userId, { app_id: this.app.valueOf(), data } , {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": schoolId
        }).then(value => {
            return new Promise((resolve) => { resolve('OK')});
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                if (reason.response.status == 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                } else {
                    reject(new Error(reason.stack.toString));
                }
            });
        });
    }

    // tslint:disable-next-line:max-line-length
    addLogoUsingPost = (id: string, data: UpdateContactAvatarUsingPutPayload, params: RequestParams = {}, headers?: Record<string, string>) =>
        this.request<BaseResponse, void>({
            path: `/Api/V1/Schools/${id}/Avatar`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.FormData,
            format: "json",
            headers: headers,
            ...params,
        })

    async addLogo(data: File): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.addLogoUsingPost(
                    this.storageService.getCurrentSession().school.id,
                    {file: data, description: data.name, name: data.name},
                    {}, {
                        "Authorization": this.storageService.getCurrentToken(),
                        "schoolId": this.storageService.getCurrentSession().school.id
                    }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                });
            } catch (err) {

            }
        });
        return promise;
    }



    // tslint:disable-next-line:max-line-length
    addDocumentActivityPost = (id: string, data: any, params: RequestParams = {}, headers?: Record<string, string>) =>
        this.request<BaseResponse, void>({
            path: `Api/V1/Activities/${id}/Resources`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.FormData,
            format: "json",
            headers: headers,
            ...params,
        })

    async addDocumentActivity(data: File, name: string, description: string, idActivity: string): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.addDocumentActivityPost(
                    idActivity,
                    {file: data, name: name, description: description, main: true},
                    {}, {
                        "Authorization": this.storageService.getCurrentToken(),
                        "schoolId": this.storageService.getCurrentSession().school.id,
                        "userId": this.storageService.getCurrentUser().id
                    }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject(new Error(reason?.response?.data?.errors?.map(value => value.description).join(',')));
                });
            } catch (err) {

            }
        });
        return promise;
    }


    // @ts-ignore
    policyPut = (id: string, request: string, headers?: Record<string, string>, params: RequestParams = {}) =>
        this.request<BaseResponse, void>({
            path: `/Api/V1/Users/${id}/Accept-Privacy-Policy`,
            method: "PUT",
            body: request,
            secure: true,
            type: ContentType.Json,
            format: "json",
            headers: headers,
            ...params,
        })


    // @ts-ignore
    async updatePolicy(userId: string): Promise<Array<any>> {
        const schoolId = this.storageService.getCurrentSession().school.id;

        await this.policyPut(userId, '' , {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": schoolId
        }).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                if (reason.response.status == 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                } else {
                    reject(new Error(reason.stack.toString));
                }
            });
        });
    }

    // @ts-ignore
    quizNotePut = (activitieId: string, deliverableId: string, request: string, headers?: Record<string, string>, params: RequestParams = {}) =>
        this.request<BaseResponse, void>({
            path: `/Api/V1/Activities/${activitieId}/Deliverables/${deliverableId}/Score`,
            method: "PUT",
            body: request,
            secure: true,
            type: ContentType.Json,
            format: "json",
            headers: headers,
            ...params,
        })

    // @ts-ignore
    async updateQuizNote(activitieId: string, deliverableId: string, data: any): Promise<Array<any>> {
        const schoolId = this.storageService.getCurrentSession().school.id;

        await this.quizNotePut(activitieId, deliverableId, data , {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": schoolId
        }).then(value => {
            return new Promise((resolve) => { resolve("OK")});
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                if (reason.response.status === 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")));
                } else {
                    reject(new Error(reason.stack.toString));
                }
            });
        });
    }

    async login(app_id: String, username: String, password: String): Promise<any> {
        const loginRequest = { password: password, userName: username };
        const loginInfo = { app_id: app_id, data: loginRequest };
        const promise = new Promise((resolve, reject) => {
            // @ts-ignore
            this.loginUsingPost(loginInfo).then(async value => {
                const session: Session = new Session();
                session.token = value.data['data']['token'];
                session.user = value.data['data']['user'];
                session.school = value.data['data']['school'];
                session.students = value.data['data']['students'];
                session.grants = value.data['data']['grants'];
                session.alerts = value.data['data'].alerts;
                session.customerResponse = value.data['data']['customer_response'];
                await this.getCycle(true, session.school.id, session.token).then(valueCicle => {
                    // @ts-ignore
                    session.cycle = valueCicle;
                }).catch(reason => {
                    // reject(JSON.stringify(reason.data));
                });
                resolve(session);
            }).catch(reason => {
                reject(reason);
            });
        });
        return promise;
    }

    async getCycle(enabled: boolean, schoolId: string, token: string): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.getAllUsingGet1({ enabled: enabled },
                    {
                        "Authorization": token,
                        "schoolId": schoolId
                    }).then(value => {
                        // @ts-ignore
                        resolve(value.data['data'][0]);
                    }).catch(reason => {
                        reject([]);
                    })
            } catch (err) {

            }
        })
        return promise;
    }

    async getAllUsersByRole(userRole: String, page: number, type: string): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            let schoolId = this.storageService.getCurrentSession().school.id;
            try {
                this.getAllUsingGet({ page: page , size: PAGE_SIZE, type: type },
                    {
                        "Authorization": this.storageService.getCurrentToken(),
                        "schoolId": schoolId
                    }).then(value => {
                        // @ts-ignore
                        resolve({data: value.data['data'], total: value.data['paging']['total']});
                    }).catch(reason => {
                        reject([]);
                    })
            } catch (err) {

            }
        })
        return promise;
    }

    async getStudentsBySpace(id: string): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            let schoolId = this.storageService.getCurrentSession().school.id;
            try {
                this.getStudentsUsingGet(id,
                    {
                        "Authorization": this.storageService.getCurrentToken(),
                        "schoolId": schoolId
                    }).then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                        reject(new Error(reason.response?.data?.errors.map(value => value.description).join(",")))
                    })
            } catch (err) {
                return new Promise((resolve, reject) => {
                    reject(new Error(err.response?.data?.errors.map(value => value.description).join(",")))
                });
            }
        })
        return promise;
    }

    async getStaffsBySpace(id: string): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            let schoolId = this.storageService.getCurrentSession().school.id;
            try {
                this.getStaffUsingGet(id,
                    {
                        "Authorization": this.storageService.getCurrentToken(),
                        "schoolId": schoolId
                    }).then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                       reject(new Error(reason.response?.data?.errors.map(value => value.description).join(",")))
                    })
            } catch (err) {

            }
        })
        return promise;
    }

    // @ts-ignore
    async createContact(contactInfo: ContactsRequest): Promise<any> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            let schoolId = this.storageService.getCurrentSession().school.id;
            try {
                this.createUsingPost({ app_id: this.app.valueOf(), data: contactInfo }, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": schoolId
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    if (reason.response.status == 400) {
                        reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                    }
                })
            } catch (err) {

            }
        })
        return promise;
    }
    // @ts-ignore
    async updateContact(contactInfo: ContactUpdateRequest): Promise<Array<any>> {
        let schoolId = this.storageService.getCurrentSession().school.id;
        await this.updateContactUsingPut(contactInfo.id, { app_id: this.app.valueOf(), data: contactInfo }, {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": schoolId
        }).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                if (reason.response.status == 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                } else {
                    reject(new Error(reason.stack.toString));

                }
            });
        });
    }

    // @ts-ignore
    async deleteContact(contactInfo: ContactResponse): Promise<Array<any>> {
        let schoolId = this.storageService.getCurrentSession().school.id;
        await this.deleteUsingDelete1(contactInfo.id, {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": schoolId
        }).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
            });
        });
    }

    // @ts-ignore
    async unassignContactsStudent(spaceId: string, contactIds: string[]): Promise<Array<any>> {
        let schoolId = this.storageService.getCurrentSession().school.id;
        await this.unassignContactsUsingPost(spaceId, { app_id: this.app.valueOf(), data: { contacts: contactIds } }, {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": schoolId
        }).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
            });
        });
    }

    /**
     * Get All Spaces information
     * @param parentId
     * @param page
     * @param type
     */
    async getAllSpacesBySpaceAsync(parentId?: string, myCourses?: boolean): Promise<SpaceNodeFlat[]> {
        const params: any = {
            parentId
        };
        if (myCourses) {
            params.assigneeUserId = this.storageService.getCurrentSession().user.contact_id;
        }
        const schoolId = this.storageService.getCurrentSession().school.id;
        try {
            const spacesResponse = await this.getAllUsingGet3(params, {'Authorization': this.storageService.getCurrentToken(), schoolId});
            if (spacesResponse && spacesResponse.data && spacesResponse.data.data) {
                // @ts-ignore
                const responseData: [] = spacesResponse.data.data;
                const hasChildren: boolean = (responseData && responseData.length > 0);
                if (hasChildren) {
                    const spaces = await Promise.all(responseData.sort((a: any, b: any) => {
                        const fa: string = a.title.toLowerCase();
                        const fb: string = b.title.toLowerCase();

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    })
                        .map(space => this.spaceToTreeNode(space))
                        .map(async space => {
                            const children: SpaceNodeFlat[] = await this.getAllSpacesBySpaceAsync(space.id, false);
                            space.children = children;
                            return space;
                        }));
                    return spaces;
                }

                return [];
            }
        } catch (e) {
            console.error('Error fetching spaces!', e);
            return [];
        }
    }

    private spaceToTreeNode(space: any): SpaceNodeFlat {
        return {
            name: space.title,
            id: space.id,
            parentId: space.parent_id,
            type: space.type,
            description: space.description,
            avatar: space.avatar.href,
            branch: space.branch
        };
    }

    /**
     * Get All Spaces information
     * @param parentId
     * @param page
     * @param type
     */
    getAllSpacesBySpaceObs(parentId?: string, myCourses?: boolean): Observable<SpaceNodeFlat> {
        const params:any = {
            parentId
        };
        if (myCourses) {
            params.assigneeUserId = this.storageService.getCurrentSession().user.contact_id;
        }
        const schoolId = this.storageService.getCurrentSession().school.id;
        this.getAllUsingGet3(params, { "Authorization": this.storageService.getCurrentToken(), schoolId })
            .then(value => {
                // @ts-ignore
                const array: any[] = value.data.data;
                const hasChildren: boolean = (array && array.length > 0);
                if (hasChildren) {
                    array.sort((a: any, b: any) => {
                        const fa: string = a.title.toLowerCase();
                        const fb: string = b.title.toLowerCase();

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    });
                    array.forEach(item => this.spaces.next(item));
                }
                if (myCourses || !hasChildren) {
                    this.spaces.complete();
                }
            }).catch((reason: any) => this.spaces.error('Error getting spaces'))
        return this.spaces.asObservable();
    }


    /**
     * Get All Spaces information
     * @param parentId
     * @param page
     * @param type
     */
    async getAllActivitiesFromSpace(spaceId: string, params): Promise<Array<any>> {
        let headers: {} = {
            Authorization: this.storageService.getCurrentToken(),
            schoolId: this.storageService.getCurrentSession().school.id,
        };
        if (params && params.from && params.to) {
            headers = {
                ...headers
            };
            params = {
                from: params.from,
                to: params.to,
            };
        }
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.getActivitiesUsingGet1(spaceId, headers, params).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    /**
     * Get All Spaces information
     * @param parentId
     * @param page
     * @param type
     */
    async getAllResourcesFromSpace(spaceId: string): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            let schoolId = this.storageService.getCurrentSession().school.id;
            try {
                this.getResourcesUsingGet1(spaceId,
                    {
                        "Authorization": this.storageService.getCurrentToken(),
                        "schoolId": schoolId
                    }).then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                        reject([]);
                    })
            } catch (err) {

            }
        })
        return promise;
    }

    async getUsersForSendMessage(page: number, size: number = 100): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.getAllUsingGet2({ page: page, size: size },
                    {}, {
                    "Authorization": this.storageService.getCurrentToken()
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async sendMessage(messageRequest: BaseRequestMessageRequest): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.sendMessageUsingPost(messageRequest,
                    {}, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id,
                    "userId": this.storageService.getCurrentUser().id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async createDraft(messageRequest: BaseRequestMessageRequest): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.createDraftUsingPost(messageRequest,
                    {}, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id,
                    "userId": this.storageService.getCurrentUser().id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async addAttachment(messageId: string, data: AddAttachmentUsingPostPayload): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.addAttachmentUsingPost(messageId, data,
                    {}, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id,
                    "userId": this.storageService.getCurrentUser().id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async markAsSent(messageId: string): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.markAsSentUsingPut(messageId, {}, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id,
                    "userId": this.storageService.getCurrentUser().id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    private readonly saveSpace = new Subject<string>();

    createSpaceObs(space: SpaceRequest): Observable<string> {
        const schoolId = this.storageService.getCurrentSession().school.id;
        this.createUsingPost2({ app_id: this.app.valueOf(), data: space }, {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": schoolId
        }).then(value => {
            const id = value.data.data['id'];
            this.saveSpace.next(id);

        }).catch(reason => {
            let error;
            return (resolve, reject) => {
                if (reason.response.status === 400) {
                    if (reason.response.data.errors) {
                       error = reject(new Error(reason.response.data.errors.map(value => value.description).join(',')));
                    } else if (reason.response.data.error) {
                        error = reject(new Error(reason.response.data.error));
                    }
                }
            };
        });
        return this.saveSpace.asObservable();
    }

    async createSpace(space: SpaceRequest): Promise<any> {
        try {
            const schoolId = this.storageService.getCurrentSession().school.id;
            const response = await this.createUsingPost2({ app_id: this.app.valueOf(), data: space }, {
                "Authorization": this.storageService.getCurrentToken(),
                "schoolId": schoolId
            });
            return await new Promise((resolve) => resolve(`Ok ${response}`));
        } catch (reason) {
            return await new Promise((resolve, reject) => {
                if (reason.response.status === 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(',')));
                }
            });
        }
    }


    async updateSpace(id: string, space: SpaceRequestUpdate): Promise<any> {
        const schoolId = this.storageService.getCurrentSession().school.id;
        try {
            const response = await this.updateUsingPutSpaces(id, { app_id: this.app.valueOf(), data: space }, {
                "Authorization": this.storageService.getCurrentToken(),
                "schoolId": schoolId
            })
            return await new Promise((resolve) => resolve(`Ok ${response}`));
        } catch (reason) {
            return await new Promise((resolve, reject) => {
                if (reason.response.status === 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(',')));
                }
            });
        }
    }

    async uploadAvatarSpace(id: string, file: UpdateAvatarUsingPutPayload): Promise<any> {
        const schoolId = this.storageService.getCurrentSession().school.id;
        const userId = this.storageService.getCurrentSession().user.id;

        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.updateAvatarUsingPut(id, file, {}, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": schoolId,
                    "userId": userId
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;

    }

    async getMessages(page: number, size: number = 100): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            let userId = this.storageService.getCurrentSession().user.id;
            try {
                this.getMessageByUserUsingGet({ page: page, size: size, userId: userId },
                    {}, {
                    "Authorization": this.storageService.getCurrentToken()
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async getMessageById(id: String): Promise<any> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            let userId = this.storageService.getCurrentSession().user.id;
            try {
                this.getMessageUsingGet(`${id}`,
                    {}, {
                    "Authorization": this.storageService.getCurrentToken()
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async getSentMessages(page: number, size: number = 100): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            let userId = this.storageService.getCurrentSession().user.id;
            try {
                this.getSendUserMessageUsingGet({ page: page, size: size, userId: userId },
                    {}, {
                    "Authorization": this.storageService.getCurrentToken()
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async replyMessages(messageId: string, messageRequest: BaseRequestMessageRequest): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.replyUsingPost(messageId, messageRequest,
                    {}, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id,
                    "userId": this.storageService.getCurrentUser().id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async messageRead(messageId: string): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.readUsingPost(messageId, {}, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "userId": this.storageService.getCurrentUser().id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async forwardMessage(messageId: string, messageRequest: BaseRequestMessageRequest): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.forwardUsingPost(messageId, messageRequest, {}, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id,
                    "userId": this.storageService.getCurrentUser().id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async deleteMessage(messageId: string): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.deleteUsingDelete2(messageId, {}, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "userId": this.storageService.getCurrentUser().id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject(reason);
                })
            } catch (err) {

            }
        })
        return promise;
    }


    // @ts-ignore
    async createActivitie(body: ActivityRequest, spaceId: string): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            const schoolId = this.storageService.getCurrentSession().school.id;
            try {
                this.addActivityUsingPost(spaceId, { app_id: this.app.valueOf(), data: body }, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": schoolId
                }).then(value => {
                    resolve(value.data['data'] as PromiseLike<any[]>);
                }).catch(reason => {
                    if (reason.response.status === 400) {
                        reject(new Error(reason.response.data.errors.map(value => value.description).join(',')));
                    }
                });
            } catch (err) { }
        });
        return promise;
    }

    // @ts-ignore
    async addRsourceToActivitie(spaceId: string, resource: AddFileResourceUsingPostPayload): Promise<Array<any>> {
        let schoolId = this.storageService.getCurrentSession().school.id;
        await this.addFileResourceUsingPost(spaceId, resource, {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": schoolId
        }).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                if (reason.response.status == 400) {
                    if (reason.response.data.errors) {
                        reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                    } else if (reason.response.data.error) {
                        reject(new Error(reason.response.data.error))
                    }
                }
            });
        });
    }

    // @ts-ignore
    async getStudentsExcel(id: string): Promise<any> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.getStudentsExcelFileUsingGet(id, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async addChilds(contactId: string, childContacts: BaseRequestContactsAddRelatedRequest): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.addRelatedUsingPut(contactId, childContacts, {}, {
                    "Authorization": this.storageService.getCurrentToken()
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async getSpaces(type: string = ''): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.getSpacesByUsingGet({ type: type }, {}, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async assignSpace(spaceId: string, spaceRequest: BaseRequestSpaceContactsRequest): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                // spaceRequest.app_id = this.app.valueOf();
                this.assignContactsUsingPost(spaceId, spaceRequest, {}, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject(reason.response.data.errors.map(value => value.description).join(","));
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async getDeliveriesByActivity(id: string) {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                // spaceRequest.app_id = this.app.valueOf();
                this.getStudentsDeliveriesUsingGet(id, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getAllContentAlerts(page?: number, size?: number, sortBy?: string) {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                // spaceRequest.app_id = this.app.valueOf();
                this.getAllAlertUsingGet({ page, size, sortBy }, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getActivity(id: string, studentId?: string) {
        const promise = new Promise((resolve, reject) => {
            try {
                // spaceRequest.app_id = this.app.valueOf();
                this.getOneUsingGet(id, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id,
                    "studentId": studentId
                }
                ).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {

            }
        })
        return promise;
    }

    async updateQualification(activityId: string, deliveryId: string, scoreActivity: BaseRequestActivityScoreRequest) {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.setScoreUsingPut(activityId, deliveryId, scoreActivity, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }
                ).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject(reason);
                });
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getOneUser(id: string) {
        const promise = new Promise((resolve, reject) => {
            try {
                // spaceRequest.app_id = this.app.valueOf();
                this.getOneUsingGet3(id, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async attachFilesActivity(id: string, files: File[]) {
        const auth = this.storageService.getCurrentToken();
        const schoolId = this.storageService.getCurrentSession().school.id;
        const userId = this.storageService.getCurrentUser().id;
        const promise = new Promise<void>((resolve, reject) => {
            try {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    this.addResourceUsingPost(id, { file, name: file.name }, {
                        "Authorization": auth,
                        "schoolId": schoolId,
                        "userId": userId
                    }).then(() => resolve()).catch(() => reject([]))
                }
            } catch (err) {
            }
        })
        return promise;
    }

    async updateActivity(id: string, data: BaseRequestOfActivityRequest) {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.updateActivityUsingPut(id, data, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }
                ).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject(reason.response.data.errors.map(value => value.description).join(",\n"));
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async updateContentAlert(id: string, alertToUpdate: ContentAlertCommentRequest) {
        const data: BaseRequestOfContentAlertCommentRequest = {
            app_id: this.app.valueOf(),
            data: alertToUpdate
        }
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.updateAlertUsingPut(id, data, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getOneResource(resourceId: string) {
        const promise = new Promise((resolve, reject) => {
            try {
                this.getOneUsingGet1(resourceId, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id,
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async removeSpace(id: string) {
        const promise = new Promise((resolve, reject) => {
            try {
                this.deleteSpaceUsingDelete(id, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id,
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getOneContact(contactId: string) {
        const promise = new Promise((resolve, reject) => {
            try {
                this.getContactUsingGet(contactId, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id,
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async removeActivityFromSpace(id: string) {
        const promise = new Promise((resolve, reject) => {
            try {
                this.deleteActivityUsingDelete(id, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id,
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async updateUser(userId: string, userToUpdate: UpdateUserRequest) {
        await this.updateUsingPut(userId, {
            app_id: this.app.valueOf(),
            data: userToUpdate
        }, {
            "Authorization": this.storageService.getCurrentToken(),
        }).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                if (reason.response.status == 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                } else {
                    reject(new Error(reason.stack.toString))
                }
            });
        });
    }

    async updateUsersPassword(userId: string, userToUpdate: UpdatePasswordUserRequest) {
        await this.changePasswordUsingPost(userId, {
            app_id: this.app.valueOf(),
            data: userToUpdate
        }, {
            "Authorization": this.storageService.getCurrentToken(),
        }).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                if (reason.response.status == 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                } else {
                    reject(new Error(reason.stack.toString))
                }
            });
        });
    }

    async updateContactAvatar(contactId: string, newAvatar: File) {
        await this.updateContactAvatarUsingPut(contactId, { file: newAvatar }, {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        }).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                if (reason.response.status === 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")));
                } else {
                    reject(new Error(reason.stack.toString));
                }
            });
        });
    }

    async removeResourceFromActivity(id: string, resourceId: string) {
        const userId = this.storageService.getCurrentSession().user.id;
        const promise = new Promise((resolve, reject) => {
            try {
                this.deleteResourceUsingDelete(id, resourceId, userId, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id,
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async disableContact(contactId: string, disableContactRequest: ContactDisableRequest) {
        await this.disableUsingPut(contactId, {
            app_id: this.app.valueOf(),
            data: disableContactRequest
        }, {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        }).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                if (reason.response.status == 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                } else {
                    reject(new Error(reason.stack.toString))
                }
            });
        });
    }

    async getContactFeeds(contactId: string) {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.allUsingGet2({
                    "Authorization": this.storageService.getCurrentToken(),
                    "studentId": contactId,
                    "schoolId": this.storageService.getCurrentSession().school.id,
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async markFeedAsRead(feedId: string, contactId: string) {
        await this.markAsReadUsingPut(feedId, {
            "Authorization": this.storageService.getCurrentToken(),
            "studentId": contactId,
            "schoolId": this.storageService.getCurrentSession().school.id,
        }).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                if (reason.response.status == 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                } else {
                    reject(new Error(reason.stack.toString))
                }
            });
        });
    }

    async sendEmailForPasswordReset(disableContactRequest: ResetPasswordRequest) {
        await this.resetPasswordRequestUsingPost({
            app_id: this.app.valueOf(),
            data: disableContactRequest
        }).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                if (reason.response.status == 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                } else {
                    reject(new Error(reason.stack.toString))
                }
            });
        });
    }

    async getAllActivities(period?: string, dateRange?: { from?: string, to?: string }) {
        let headers: {} = {
            Authorization: this.storageService.getCurrentToken(),
            schoolId: this.storageService.getCurrentSession().school.id,
        };

        const queryParams = {
            period,
            ...dateRange
        };

        const loggedUser = this.storageService.getCurrentUser();
        if (loggedUser.contact_id) {
            headers = {
                ...headers,
                staffId: loggedUser.contact_id,
            };
        }
        const promise = new Promise((resolve, reject) => {
            try {
                this.allUsingGet({...queryParams}, headers).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                });
            } catch (err) {
                console.log('Error: ', err);
            }
        });
        return promise;
    }

    async confirmPasswordReset(hash_token: string, passwordChange: ConfirmPasswordReset) {
        await this.userUpdatePasswordUsingPost(hash_token, {
            app_id: this.app.valueOf(),
            data: passwordChange
        }).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                if (reason.response.status == 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                } else {
                    reject(new Error(reason.stack.toString))
                }
            });
        });
    }

    getAllSpacesByContactId(contactId: string) {
        const params = {
            assigneeUserId: contactId,
        };
        let headers: {} = {
            Authorization: this.storageService.getCurrentToken(),
            schoolId: this.storageService.getCurrentSession().school.id,
        };
        const loggedUser = this.storageService.getCurrentUser();
        if (loggedUser.contact_id) {
            headers = {
                ...headers,
                staffId: loggedUser.contact_id,
            }
        }
        const promise = new Promise((resolve, reject) => {
            try {
                this.getAllUsingGet3(params, headers).then(response => {
                    // @ts-ignore
                    const contactSpaces: any[] = response.data.data;
                    if (contactSpaces && contactSpaces.length > 0) {
                        contactSpaces.sort((a, b) => {
                            let fa = a.title.toLowerCase(),
                                fb = b.title.toLowerCase();
                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        });
                    }
                    // @ts-ignore
                    resolve(contactSpaces);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getLoadsBySchoolId() {
        const promise = new Promise((resolve, reject) => {
            try {
                this.getAllUsingGet3({
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id,
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    return new Promise((resolve, reject) => {
                        if (reason.response.status == 400) {
                            reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                        } else {
                            reject(new Error(reason.stack.toString))
                        }
                    });
                });
            } catch (err) {
                console.log("Error: ", err);
            }
        });
        return promise;
    }


    async sendFileForUploadAuth(fileToSend: File, type: string) {
        return this.sendFileForUploadAuthFull(fileToSend, type, null, false);
    }

    async sendFileForUploadAuthFull(fileToSend: File, type: string, targetSpaceId: string, createUser: boolean, separator: string = ',') {
        const file: CreateUsingPost1Payload = {
            file: fileToSend,
            name: fileToSend.name,
            skipHeaders: true,
            type,
            separator,
            targetSpaceId,
            createUser
        };
        const promise = new Promise((resolve, reject) => {
            try {
                this.createUsingPost1(file, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id,
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    return new Promise((resolve, reject) => {
                        if (reason.response.status == 400) {
                            reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                        } else {
                            reject(new Error(reason.stack.toString))
                        }
                    });
                });
            } catch (err) {
                console.log("Error: ", err);
            }
        });
        return promise;
    }

    async decideOnPendingFileUpload(decision: string, fileId: string) {
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        switch (decision) {
            case 'start':
                const startPromise = new Promise((resolve, reject) => {
                    try {
                        this.startUsingPut1(fileId, headers).then(value => {
                            // @ts-ignore
                            resolve(value.data['data']);
                        }).catch(reason => {
                            return new Promise((resolve, reject) => {
                                if (reason.response.status == 400) {
                                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                                } else {
                                    reject(new Error(reason.stack.toString))
                                }
                            });
                        });
                    } catch (err) {
                        console.log("Error: ", err);
                    }
                })
                return startPromise;
            case 'cancel':
                const cancelPromise = new Promise((resolve, reject) => {
                    try {
                        this.cancelUsingPut(fileId, headers).then(value => {
                            // @ts-ignore
                            resolve(value.data['data']);
                        }).catch(reason => {
                            return new Promise((resolve, reject) => {
                                if (reason.response.status == 400) {
                                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                                } else {
                                    reject(new Error(reason.stack.toString))
                                }
                            });
                        });
                    } catch (err) {
                        console.log("Error: ", err);
                    }
                })
                return cancelPromise;
            default:
                break;
        }
    }

    async getAllSecurityRoles(page?: number, size?: number, sortBy?: string, orderType?: string) {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                // spaceRequest.app_id = this.app.valueOf();
                this.getAllUsingGet5({ orderType, page, size, sortBy }, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async toggleEnableSecurityRoles(id: string, toggleAction: boolean) {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                let func;
                if (toggleAction) {
                    func = this.enableUsingPut;
                } else {
                    func = this.disableUsingPut1;
                }
                func(id, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async createSecurityRole(data: CreateRole) {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {

                this.createUsingPost4({ app_id: 'admin-ui', data }, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async updateSecurityRole(id: string, data: UpdateRole) {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {

                this.updateUsingPut1(id, { app_id: 'admin-ui', data }, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getAllUIGrants(page?: number, size?: number, sortBy?: string, orderType?: string) {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.getAllUiGrantsUsingGet(
                    {
                        "Authorization": this.storageService.getCurrentToken(),
                        "schoolId": this.storageService.getCurrentSession().school.id
                    }
                ).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getSecurityRole(id: string) {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.getOneUsingGet4(id, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async deleteSecurityRole(id: string) {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {

                this.deleteUsingDelete4(id, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async updateSecurityRoleForContact(data: UpdateUserRoleRequest, userId: string) {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.userUpdateRoleUsingPut(userId, { app_id: 'app', data }, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    if (reason.response.status == 400) {
                        reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                    } else {
                        reject(new Error(reason.stack.toString))
                    }
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    getSpacesForContactsAssign(parentId?: string, myCourses?: boolean) {
        const params = {
            parentId
        };
        if (myCourses) {
            params['assigneeUserId'] = this.storageService.getCurrentSession().user.contact_id;
        }
        const schoolId = this.storageService.getCurrentSession().school.id;
        const promise = new Promise((resolve, reject) => {
            try {
                this.getAllUsingGet3(params, { "Authorization": this.storageService.getCurrentToken(), schoolId })
                    .then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                        reject([]);
                    })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getContactChildren(contactId: string): Promise<Array<any>> {
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.getRelatedContactsUsingGet(contactId, {
                    "Authorization": this.storageService.getCurrentToken(),
                    "schoolId": this.storageService.getCurrentSession().school.id
                }).then(value => {
                    // @ts-ignore
                    resolve(value.data['data']);
                }).catch(reason => {
                    reject([]);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async unrelateStudentsFromParent(parentId: string, studentsIds: string[]) {
        let schoolId = this.storageService.getCurrentSession().school.id;
        await this.deleteRelatedUsingDelete(parentId, { app_id: this.app.valueOf(), data: { childs: studentsIds } }, {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": schoolId
        }).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
            });
        });
    }

    async getAllCycles() {
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.getAllCyclesUsingGet({}, headers)
                    .then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                        reject([]);
                    });
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getActiveCycle() {
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<any>((resolve, reject) => {
            try {
                this.getOpenCycleUsingGet({}, headers)
                    .then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                        reject([]);
                    });
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    // tslint:disable-next-line:typedef
    async getActiveCycleBySpaceOrAssigment(contactId: string) {
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<any>((resolve, reject) => {
            try {
                this.getOpenCycleBySpaceOrAssigment(contactId,{}, headers)
                    .then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                        reject([]);
                    });
            } catch (err) {
                console.log("Error: ", err);
            }
        });
        return promise;
    }

    async createCycle(cycle: CycleUpdateRequest) {
        const data: BaseRequestOfCycleRequest = {
            app_id: this.app.valueOf(),
            data: cycle
        };
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<any>((resolve, reject) => {
            try {
                this.createCycleUsingPost2(data, headers)
                    .then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                        reject([]);
                    });
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async createAndActivateCycle(cycle: CycleUpdateRequest) {
        const newActiveCycleRequest: BaseRequestOfCycleRequest = {
            app_id: this.app.valueOf(),
            data: cycle,
        };
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<any>((resolve, reject) => {
            try {
                this.createCycleUsingPost2(newActiveCycleRequest, headers)
                    .then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                        reject([]);
                    })
            } catch (err) {
                console.log("Error: ", err);
            }
        });
        return promise;
    }

    async updateCycle(id: string, cycleToUpdate: Partial<CycleUpdateRequest>) {
        const data: BaseRequestOfCycleUpdateRequest = {
            app_id: this.app.valueOf(),
            data: cycleToUpdate
        };
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<any>((resolve, reject) => {
            try {
                this.updateCycleUsingPut1(id, data, headers)
                    .then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                        reject([]);
                    })
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getCycleUnits(cycleId: string) {
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.getAllUnitsUsingGet(cycleId, headers)
                    .then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                        reject([]);
                    });
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getScoresBySpace(spaceId: string) {
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<Array<any>>((resolve, reject) => {
            try {
                this.getAllScoreLoadsUsingGet(spaceId, headers)
                    .then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                        reject([]);
                    });
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async createManualScoreLoad(spaceId: string, scores: ScoreLoadResquest) {
        const data: BaseRequestOfScoreLoadResquest = {
            app_id: this.app.valueOf(),
            data: scores
        };
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<any>((resolve, reject) => {
            try {
                this.createScoreLoadUsingPost(spaceId, data, headers)
                    .then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                        reject([]);
                    });
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getScoreInfo(spaceId:string, scoreId: string) {
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<any>((resolve, reject) => {
            try {
                this.getScoreLoadUsingGet(spaceId, scoreId, headers)
                    .then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                        reject([]);
                    });
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async deleteScore(spaceId: string, scoreId: string, deleteScoreRequest: ScoreLoadDeleteResquest) {
        const data: BaseRequestOfScoreLoadDeleteResquest = {
            app_id: this.app.valueOf(),
            data: deleteScoreRequest
        };
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        await this.deleteScoreLoadUsingDelete(spaceId, scoreId, data, headers).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                if (reason.response.status == 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")))
                } else {
                    reject(new Error(reason.stack.toString))
                }
            });
        });
    }

    async downloadScoreInfo(spaceId:string, scoreId: string) {
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<any>((resolve, reject) => {
            try {
                this.downloadScoreLoadUsingGet(spaceId, scoreId, headers)
                    .then(value => {
                        // @ts-ignore
                        resolve(value.data);
                    }).catch(reason => {
                        reject([]);
                    });
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getSpaceScoreInfo(spaceId:string, queryParams?: { contact_id?: string; unit_id?: string; }) {
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<any>((resolve, reject) => {
            try {
                this.getScoreOfSpaceUsingGet(spaceId, queryParams, headers)
                    .then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                        reject([]);
                    });
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }

    async getSpaceBreadCrumb(spaceId:string) {
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<any>((resolve, reject) => {
            try {
                this.getSpaceBreadCrumbGet(spaceId, {}, headers)
                    .then(value => {
                        resolve(value.data.data);
                    }).catch(reason => {
                        reject([]);
                    });
            } catch (err) {
                console.log('Error: ', err);
            }
        })
        return promise;
    }

    async getContactsIndicators() {
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<any>((resolve, reject) => {
            try {
                this.getContactsIndicatorsGet({}, headers)
                    .then(value => {
                        resolve(value.data.data);
                    }).catch(reason => {
                        reject([]);
                    });
            } catch (err) {
                console.log('Error: ', err);
            }
        })
        return promise;
    }

    async getCustomerBalanceById(customerId:string) {
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
        };
        const promise = new Promise<any>((resolve, reject) => {
            try {
                this.getCustomerBalance(customerId, {}, headers)
                    .then(value => {
                        resolve(value.data.data);
                    }).catch(reason => {
                    reject([]);
                });
            } catch (err) {
                console.log('Error: ', err);
            }
        })
        return promise;
    }

    async makePayment(customerId: string, paymentRequest: PaymentRequest) {
        const data: BaseRequest = {
            app_id: this.app.valueOf(),
            data: paymentRequest
        };
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
        };
        const promise = new Promise<any>((resolve, reject) => {
            try {
                this.postPayment(customerId, data, headers)
                    .then(value => {
                        // @ts-ignore
                        resolve(value.data['data']);
                    }).catch(reason => {
                    reject([]);
                });
            } catch (err) {
                console.log("Error: ", err);
            }
        })
        return promise;
    }


    async uploadPayImage(customerId: string, paymentId: string, file: File) {
        await this.uploadPayImagePost(customerId, paymentId, { file: file }, {
            "Authorization": this.storageService.getCurrentToken(),
            "schoolId": this.storageService.getCurrentSession().school.id,
            "userId": this.storageService.getCurrentUser().id,
            "Content-Type": file.type,
        }).then(value => {
            return new Promise((resolve) => { resolve("OK") });
        }).catch(reason => {
            return new Promise((resolve, reject) => {
                if (reason.response.status === 400) {
                    reject(new Error(reason.response.data.errors.map(value => value.description).join(",")));
                } else {
                    reject(new Error(reason.stack.toString));
                }
            });
        });
    }

    async suggestions(suggestionRequest: SuggestionRequest){
        const data: BaseRequest = {
            data: suggestionRequest,
        };
        const headers = {
            "Authorization": this.storageService.getCurrentToken(),
        };

        return new Promise<any>((resolve, reject) => {
            this.suggestionsPost(data, headers)
                .then(value => {
                    resolve(value?.data?.data);
                })
                .catch(reason => {
                    if (reason.response.status === 400) {
                        reject(new Error(reason.response.data.errors.map(value => value.description).join(",")));
                    }
                    else {
                        reject(new Error(reason.stack.toString));
                    }
                });
        });
    }


}
