/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    AddAttachmentUsingPostPayload,
    AddFileResourceUsingPostPayload,
    AddResourceUsingPostPayload,
    AttachFileAssetsUsingPostPayload,
    AttachFileUsingPostPayload,
    AttachLinkRequest,
    BaseRequestActivityRequest,
    BaseRequestActivityScoreRequest,
    BaseRequestContactsAddRelatedRequest,
    BaseRequestContactsRequest,
    BaseRequestContactUpdateRequest,
    BaseRequestCreateUserRequest,
    BaseRequestOfActivityRequest,
    BaseRequestCycleRequest,
    BaseRequestLoginUserRequest,
    BaseRequestMessageRequest,
    BaseRequestMessageResponseRequest,
    BaseRequestSpaceContactsRequest,
    BaseRequestSpaceRequest,
    BaseRequestSpaceRequestUpdate,
    BaseRequestUpdatePasswordUserRequest,
    BaseRequestUpdateUserRequest,
    BaseResponse,
    CompletableFutureBaseResponseAllUsersResponse,
    CompletableFutureBaseResponseChangePasswordUserResponse,
    CompletableFutureBaseResponseDeletedUserResponse,
    CompletableFutureBaseResponseDisabledUserResponse,
    CompletableFutureBaseResponseListMessagesUserResponse,
    CompletableFutureBaseResponseLoginUserResponse,
    CompletableFutureBaseResponseUpdateUserResponse,
    CompletableFutureBaseResponseUserInfoResponse,
    GenericAddCommentRequest,
    UpdateAvatarUsingPutPayload,
    UpdateContactAvatarUsingPut1Payload,
    UpdateContactAvatarUsingPutPayload,
    BaseRequestOfContentAlertRequest,
    BaseRequestOfContentAlertCommentRequest,
    BaseRequestOfContactDisableRequest,
    BaseRequestOfResetPasswordRequest,
    BaseRequestOfUpdatePasswordUserRequest,
    CreateUsingPost1Payload,
    BaseRequestOfUpdateRole,
    BaseRequestOfCreateRole,
    BaseRequestOfUpdateUserRoleRequest,
    BaseRequestOfContactsDeleteRelatedRequest,
    BaseRequestOfCycleRequest,
    BaseRequestOfCycleUnitRequest,
    BaseRequestOfCycleUpdateRequest,
    BaseRequestOfScoreLoadResquest,
    BaseRequestOfScoreLoadDeleteResquest, PaymentRequest, BaseRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags activities-controller
   * @name AllUsingGet
   * @summary Get All
   * @request GET:/Api/V1/Activities
   * @secure
   */
  allUsingGet = (query: { period?: string, from?: string, to?:string }, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities`,
      method: "GET",
      query: query,
      secure: true,
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags activities-controller
   * @name GetOneUsingGet
   * @summary Get one
   * @request GET:/Api/V1/Activities/{id}
   * @secure
   */
  getOneUsingGet = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities/${id}`,
      method: "GET",
      secure: true,
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags activities-controller
   * @name AttachFileAssetsUsingPost
   * @summary Attach file to assets
   * @request POST:/Api/V1/Activities/{id}/Assets
   * @secure
   */
  attachFileAssetsUsingPost = (id: string, data: AttachFileAssetsUsingPostPayload, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities/${id}/Assets`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags activities-controller
   * @name AttachFileUsingPost
   * @summary Attach delivery file to activity
   * @request POST:/Api/V1/Activities/{id}/Attach
   * @secure
   */
  attachFileUsingPost = (id: string, userId: AttachFileUsingPostPayload, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities/${id}/Attach`,
      method: "POST",
      body: userId,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags activities-controller
   * @name AttachUrlUsingPost
   * @summary Attach URL to activity
   * @request POST:/Api/V1/Activities/{id}/Attach-Link
   * @secure
   */
  attachUrlUsingPost = (id: string, userId: AttachLinkRequest, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities/${id}/Attach-Link`,
      method: "POST",
      body: userId,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags activities-controller
   * @name CommentUsingPut
   * @summary Add comment
   * @request PUT:/Api/V1/Activities/{id}/Comments
   * @secure
   */
  commentUsingPut = (id: string, userId: GenericAddCommentRequest, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities/${id}/Comments`,
      method: "PUT",
      body: userId,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags activities-controller
   * @name CompleteUsingPut
   * @summary Start activity
   * @request PUT:/Api/V1/Activities/{id}/Complete
   * @secure
   */
  completeUsingPut = (id: string, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities/${id}/Complete`,
      method: "PUT",
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags activities-controller
   * @name DetachDeliveryUsingDelete
   * @summary Detach delivery file or URL from activity
   * @request DELETE:/Api/V1/Activities/{id}/Detach/{delivery_id}
   * @secure
   */
  detachDeliveryUsingDelete = (deliveryId: string, id: string, userId: string, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities/${id}/Detach/${deliveryId}`,
      method: "DELETE",
      body: userId,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags activities-controller
   * @name AddResourceUsingPost
   * @summary Add file resources to activity
   * @request POST:/Api/V1/Activities/{id}/Resources
   * @secure
   */
  addResourceUsingPost = (id: string, userId: AddResourceUsingPostPayload, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities/${id}/Resources`,
      method: "POST",
      body: userId,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
 * No description
 *
 * @tags activities-controller
 * @name UpdateActivityUsingPut
 * @summary Update activity
 * @request PUT:/Api/V1/Activities/{id}
 * @secure
 */
  updateActivityUsingPut = (id: string, activityRequest: BaseRequestOfActivityRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities/${id}`,
      method: "PUT",
      body: activityRequest,
      secure: true,
      type: ContentType.Json,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags activities-controller
   * @name StartUsingPut
   * @summary Start activity
   * @request PUT:/Api/V1/Activities/{id}/Start
   * @secure
   */
  startUsingPut = (id: string, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities/${id}/Start`,
      method: "PUT",
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags classrooms-controller
   * @name AllUsingGet1
   * @summary Get all
   * @request GET:/Api/V1/Classrooms
   * @secure
   */
  allUsingGet1 = (params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Classrooms`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags classrooms-controller
   * @name GetActivitiesUsingGet
   * @summary Get activities
   * @request GET:/Api/V1/Classrooms/{id}/Activities
   * @secure
   */
  getActivitiesUsingGet = (id: string, query: { period: string }, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Classrooms/${id}/Activities`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags classrooms-controller
   * @name GetResourcesUsingGet
   * @summary Get resources
   * @request GET:/Api/V1/Classrooms/{id}/Resources
   * @secure
   */
  getResourcesUsingGet = (id: string, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Classrooms/${id}/Resources`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags comments-controller
   * @name DeleteUsingDelete
   * @summary Delete comment
   * @request DELETE:/Api/V1/Comments/{id}
   * @secure
   */
  deleteUsingDelete = (id: string, userId: string, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Comments/${id}`,
      method: "DELETE",
      body: userId,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags contacts-controller
   * @name GetAllUsingGet
   * @summary Get all contacts
   * @request GET:/Api/V1/Contacts
   * @secure
   */
  getAllUsingGet = (query?: { page?: number; size?: number; type?: string }, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Contacts`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags contacts-controller
   * @name CreateUsingPost
   * @summary Add Contacts
   * @request POST:/Api/V1/Contacts
   * @secure
   */
  createUsingPost = (contactsRequest: BaseRequestContactsRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Contacts`,
      method: "POST",
      body: contactsRequest,
      secure: true,
      type: ContentType.Json,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags contacts-controller
   * @name GetContactUsingGet
   * @summary Get contact by id
   * @request GET:/Api/V1/Contacts/{id}
   * @secure
   */
  getContactUsingGet = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Contacts/${id}`,
      method: "GET",
      secure: true,
      headers: headers,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags contacts-controller
   * @name UpdateContactUsingPut
   * @summary Update Contacts
   * @request PUT:/Api/V1/Contacts/{id}
   * @secure
   */
  updateContactUsingPut = (id: string, request: BaseRequestContactUpdateRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Contacts/${id}`,
      method: "PUT",
      body: request,
      secure: true,
      type: ContentType.Json,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags contacts-controller
   * @name DeleteUsingDelete1
   * @summary Delete Contacts Avatar
   * @request DELETE:/Api/V1/Contacts/{id}
   * @secure
   */
  deleteUsingDelete1 = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Contacts/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags contacts-controller
   * @name UpdateContactAvatarUsingPut
   * @summary Update Contacts Avatar
   * @request PUT:/Api/V1/Contacts/{id}/Avatar
   * @secure
   */
  updateContactAvatarUsingPut = (id: string, data: UpdateContactAvatarUsingPutPayload, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Contacts/${id}/Avatar`,
      method: "PUT",
      body: data,
      secure: true,
      headers: headers,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags contacts-controller
   * @name AddRelatedUsingPut
   * @summary Child Contacts
   * @request PUT:/Api/V1/Contacts/{id}/Childs
   * @secure
   */
  addRelatedUsingPut = (id: string, childContacts: BaseRequestContactsAddRelatedRequest, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Contacts/${id}/Childs`,
      method: "PUT",
      body: childContacts,
      secure: true,
      type: ContentType.Json,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags contacts-controller
   * @name GetRelatedContactsUsingGet
   * @summary Related Contacts (childs)
   * @request GET:/Api/V1/Contacts/{id}/Related
   * @secure
   */
  getRelatedContactsUsingGet = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Contacts/${id}/Related`,
      method: "GET",
      secure: true,
      format: "json",
      headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags cycles-controller
   * @name GetAllUsingGet1
   * @summary Get open cycle
   * @request GET:/Api/V1/Cycles
   * @secure
   */
  getAllUsingGet1 = (query?: { enabled?: boolean }, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Cycles`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags cycles-controller
   * @name CreateAndEnableUsingPost
   * @summary Get contact by id
   * @request POST:/Api/V1/Cycles/Open
   * @secure
   */
  createAndEnableUsingPost = (request: BaseRequestCycleRequest, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Cycles/Open`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags feeds-controller
   * @name AllUsingGet2
   * @summary Get All Fees
   * @request GET:/Api/V1/Feeds
   * @secure
   */
  allUsingGet2 = (headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Feeds`,
      method: "GET",
      secure: true,
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags feeds-controller
   * @name MarkAsReadUsingPut
   * @summary Mark feed as read
   * @request PUT:/Api/V1/Feeds/{id}/Read
   * @secure
   */
  markAsReadUsingPut = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Feeds/${id}/Read`,
      method: "PUT",
      secure: true,
      headers: headers,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags messages-controller
   * @name GetMessageByUserUsingGet
   * @summary Get User Messages
   * @request GET:/Api/V1/Messages
   * @secure
   */
  getMessageByUserUsingGet = (query: { page: number; size: number; userId: string }, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Messages`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags messages-controller
   * @name CreateDraftUsingPost
   * @summary Add Message
   * @request POST:/Api/V1/Messages/Draft
   * @secure
   */
  createDraftUsingPost = (messageRequest: BaseRequestMessageRequest, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Messages/Draft`,
      method: "POST",
      body: messageRequest,
      secure: true,
      type: ContentType.Json,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags messages-controller
   * @name SendMessageUsingPost
   * @summary Add Message
   * @request POST:/Api/V1/Messages/Send
   * @secure
   */
  sendMessageUsingPost = (messageRequest: BaseRequestMessageRequest, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Messages/Send`,
      method: "POST",
      body: messageRequest,
      secure: true,
      type: ContentType.Json,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags messages-controller
   * @name GetSendUserMessageUsingGet
   * @summary Get User Send Messages
   * @request GET:/Api/V1/Messages/Sent
   * @secure
   */
  getSendUserMessageUsingGet = (query: { page: number; size: number, userId: string }, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Messages/Sent`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags messages-controller
   * @name GetAllUsingGet2
   * @summary getAll
   * @request GET:/Api/V1/Messages/Users
   * @secure
   */
  getAllUsingGet2 = (
    query?: { orderType?: string; page?: number; size?: number; sortBy?: string },
    params: RequestParams = {},
    headers?: Record<string, string>
  ) =>
    this.request<CompletableFutureBaseResponseListMessagesUserResponse, void>({
      path: `/Api/V1/Messages/Users`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags messages-controller
   * @name GetMessageUsingGet
   * @summary Get Message
   * @request GET:/Api/V1/Messages/{id}
   * @secure
   */
  getMessageUsingGet = (id: string, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Messages/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags messages-controller
   * @name DeleteUsingDelete2
   * @summary Delete Message
   * @request DELETE:/Api/V1/Messages/{id}
   * @secure
   */
  deleteUsingDelete2 = (id: string, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Messages/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags messages-controller
   * @name ReadUsingPost
   * @summary Message read
   * @request POST:/Api/V1/Messages/{id}/Read
   * @secure
   */
  readUsingPost = (id: string, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Messages/${id}/Read`,
      method: "POST",
      secure: true,
      type: ContentType.Json,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags messages-controller
   * @name ForwardUsingPost
   * @summary Resend message
   * @request POST:/Api/V1/Messages/{id}/Resend
   * @secure
   */
  forwardUsingPost = (id: string, messageRequest: BaseRequestMessageRequest, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Messages/${id}/Resend`,
      method: "POST",
      body: messageRequest,
      secure: true,
      type: ContentType.Json,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags messages-controller
   * @name ReplyUsingPost
   * @summary Response message
   * @request POST:/Api/V1/Messages/{id}/Response
   * @secure
   */
  replyUsingPost = (id: string, messageRequest: BaseRequestMessageResponseRequest, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Messages/${id}/Response`,
      method: "POST",
      body: messageRequest,
      secure: true,
      type: ContentType.Json,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags messages-controller
   * @name AddAttachmentUsingPost
   * @summary Add Message Attachment
   * @request POST:/Api/V1/Messages/{messageId}/Attachments
   * @secure
   */
  addAttachmentUsingPost = (messageId: string, data: AddAttachmentUsingPostPayload, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Messages/${messageId}/Attachments`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags messages-controller
   * @name DeleteAttachmentUsingDelete
   * @summary Delete Message Attachment
   * @request DELETE:/Api/V1/Messages/{messageId}/Attachments/{id}
   * @secure
   */
  deleteAttachmentUsingDelete = (id: string, messageId: string, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Messages/${messageId}/Attachments/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags messages-controller
   * @name MarkAsSentUsingPut
   * @summary Mark Message As Sent
   * @request PUT:/Api/V1/Messages/{messageId}/Sent
   * @secure
   */
  markAsSentUsingPut = (messageId: string, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Messages/${messageId}/Sent`,
      method: "PUT",
      secure: true,
      type: ContentType.Json,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags spaces-controller
   * @name GetAllUsingGet3
   * @summary Get All Spaces
   * @request GET:/Api/V1/Spaces
   * @secure
   */
  getAllUsingGet3 = (query?, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags spaces-controller
   * @name CreateUsingPost2
   * @summary Create Spaces
   * @request POST:/Api/V1/Spaces
   * @secure
   */
  createUsingPost2 = (spaceRequest: BaseRequestSpaceRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces`,
      method: "POST",
      body: spaceRequest,
      secure: true,
      type: ContentType.Json,
      headers: headers,
      ...params,
    });

  getSpacesByUsingGet = (query?: { name?: string; tags?: string; type?: string }, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/Find`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags spaces-controller
   * @name UpdateUsingPutSpaces
   * @summary Update Spaces
   * @request PUT:/Api/V1/Spaces/{id}
   * @secure
   */
  updateUsingPutSpaces = (id: string, spaceRequest: BaseRequestSpaceRequestUpdate, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${id}`,
      method: "PUT",
      body: spaceRequest,
      secure: true,
      type: ContentType.Json,
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags spaces-controller
   * @name GetActivitiesUsingGet1
   * @summary Get activities
   * @request GET:/Api/V1/Spaces/{id}/Activities
   * @secure
   */
  getActivitiesUsingGet1 = (id: string, headers?: Record<string, string>, query?: { period: string }, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${id}/Activities`,
      method: "GET",
      secure: true,
      query: query,
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags spaces-controller
   * @name UpdateAvatarUsingPut
   * @summary Update Spaces Avatar
   * @request PUT:/Api/V1/Spaces/{id}/Avatar
   * @secure
   */
  updateAvatarUsingPut = (id: string, data: UpdateAvatarUsingPutPayload, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${id}/Avatar`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags spaces-controller
   * @name GetResourcesUsingGet1
   * @summary Get resources
   * @request GET:/Api/V1/Spaces/{id}/Resources
   * @secure
   */
  getResourcesUsingGet1 = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${id}/Resources`,
      method: "GET",
      secure: true,
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags spaces-controller
   * @name GetStudentsUsingGet
   * @summary Get Students
   * @request GET:/Api/V1/Spaces/{id}/Students
   * @secure
   */
  getStudentsUsingGet = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${id}/Students`,
      method: "GET",
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags spaces-controller
   * @name GetStaffUsingGet
   * @summary Get Staff assigned to space
   * @request GET:/Api/V1/Spaces/{id}/Staff
   * @secure
   */
  getStaffUsingGet = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${id}/Staff`,
      method: "GET",
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags spaces-controller
   * @name AddActivityUsingPost
   * @summary Add activity
   * @request POST:/Api/V1/Spaces/{spaceId}/Activities
   * @secure
   */
  addActivityUsingPost = (spaceId: string, userId: BaseRequestActivityRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${spaceId}/Activities`,
      method: "POST",
      body: userId,
      secure: true,
      type: ContentType.Json,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags spaces-controller
   * @name AssignContactsUsingPost
   * @summary Assign Contacts to Spaces
   * @request POST:/Api/V1/Spaces/{spaceId}/Assign
   * @secure
   */
  assignContactsUsingPost = (spaceId: string, body: BaseRequestSpaceContactsRequest, params: RequestParams = {}, headers?: Record<string, string>) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${spaceId}/Assign`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags spaces-controller
   * @name AddFileResourceUsingPost
   * @summary Attach file to resources
   * @request POST:/Api/V1/Spaces/{spaceId}/Resources
   * @secure
   */
  addFileResourceUsingPost = (spaceId: string, userId: AddFileResourceUsingPostPayload, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${spaceId}/Resources`,
      method: "POST",
      body: userId,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags users-controller
   * @name CreateUsingPost3
   * @summary Create
   * @request POST:/Api/V1/Users
   * @secure
   */
  createUsingPost3 = (userId: BaseRequestCreateUserRequest, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Users`,
      method: "POST",
      body: userId,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags users-controller
   * @name UpdateContactAvatarUsingPut1
   * @summary Update User Avatar
   * @request PUT:/Api/V1/Users/Avatar
   * @secure
   */
  updateContactAvatarUsingPut1 = (data: UpdateContactAvatarUsingPut1Payload, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Users/Avatar`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users-controller
   * @name LoginUsingPost
   * @summary login
   * @request POST:/Api/V1/Users/Login
   * @secure
   */
  loginUsingPost = (request: BaseRequestLoginUserRequest, params: RequestParams = {}) =>
    this.request<CompletableFutureBaseResponseLoginUserResponse, void>({
      path: `/Api/V1/Users/Login`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags users-controller
   * @name GetAllUsingGet4
   * @summary getAll
   * @request GET:/Api/V1/Users/all
   * @secure
   */
  getAllUsingGet4 = (
    query: { orderType: string; page: number; size: number; sortBy: string },
    params: RequestParams = {},
  ) =>
    this.request<CompletableFutureBaseResponseAllUsersResponse, void>({
      path: `/Api/V1/Users/all`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags users-controller
   * @name DeleteUsingDelete3
   * @summary delete
   * @request DELETE:/Api/V1/Users/delete/{id}
   * @secure
   */
  deleteUsingDelete3 = (id: string, userId: string, params: RequestParams = {}) =>
    this.request<CompletableFutureBaseResponseDeletedUserResponse, void>({
      path: `/Api/V1/Users/delete/${id}`,
      method: "DELETE",
      body: userId,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags users-controller
   * @name DisableUsingPost
   * @summary disable
   * @request POST:/Api/V1/Users/disable/{id}
   * @secure
   */
  disableUsingPost = (id: string, userId: string, params: RequestParams = {}) =>
    this.request<CompletableFutureBaseResponseDisabledUserResponse, void>({
      path: `/Api/V1/Users/disable/${id}`,
      method: "POST",
      body: userId,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags users-controller
   * @name ChangePasswordUsingPost
   * @summary changePassword
   * @request POST:/Api/V1/Users/resetPassword/{id}
   * @secure
   */
  changePasswordUsingPost = (id: string, userId: BaseRequestUpdatePasswordUserRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<CompletableFutureBaseResponseChangePasswordUserResponse, void>({
      path: `/Api/V1/Users/resetPassword/${id}`,
      method: "POST",
      body: userId,
      secure: true,
      headers: headers,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags users-controller
   * @name UpdateUsingPut
   * @summary update
   * @request PUT:/Api/V1/Users/update/{id}
   * @secure
   */
  updateUsingPut = (id: string, userId: BaseRequestUpdateUserRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<CompletableFutureBaseResponseUpdateUserResponse, void>({
      path: `/Api/V1/Users/update/${id}`,
      method: "PUT",
      body: userId,
      secure: true,
      headers: headers,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags users-controller
   * @name GetOneUsingGet1
   * @summary getOne
   * @request GET:/Api/V1/Users/{id}
   * @secure
   */
  getOneUsingGet3 = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<CompletableFutureBaseResponseUserInfoResponse, void>({
      path: `/Api/V1/Users/${id}`,
      method: "GET",
      secure: true,
      headers: headers,
      ...params,
    });
  /**
     * No description
     *
     * @tags activities-controller
     * @name GetStudentsDeliveriesUsingGet
     * @summary Get score deliverables
     * @request GET:/Api/V1/Activities/{id}/Deliverables
     * @secure
     */
  getStudentsDeliveriesUsingGet = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities/${id}/Deliverables`,
      method: "GET",
      secure: true,
      headers: headers,
      ...params,
    });
  /**
    * No description
    *
    * @tags spaces-controller
    * @name UnassignContactsUsingPost
    * @summary Assign Contacts to Spaces
    * @request POST:/Api/V1/Spaces/{spaceId}/Unassign
    * @secure
    */
  unassignContactsUsingPost = (spaceId: string, body: BaseRequestSpaceContactsRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${spaceId}/Unassign`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      headers: headers,
      ...params,
    });

  /**
 * No description
 *
 * @tags spaces-controller
 * @name GetStudentsExcelFileUsingGet
 * @summary /{id}/Students/Excel
 * @request GET:/Api/V1/Spaces/{id}/Students/Excel
 * @secure
 */
  getStudentsExcelFileUsingGet = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/Api/V1/Spaces/${id}/Students/Excel`,
      method: "GET",
      secure: true,
      headers: headers,
      ...params,
    });

  /**
   * No description
   *
   * @tags activities-controller
   * @name SetScoreUsingPut
   * @summary Set score activity
   * @request PUT:/Api/V1/Activities/{id}/Deliverables/{idDeliv}/Score
   * @secure
   */
  setScoreUsingPut = (
    id: string,
    idDeliv: string,
    request: BaseRequestActivityScoreRequest,
    headers?: Record<string, string>,
    params: RequestParams = {},
  ) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities/${id}/Deliverables/${idDeliv}/Score`,
      method: "PUT",
      body: request,
      secure: true,
      type: ContentType.Json,
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags content-alert-controller
   * @name CreateAlertUsingPost
   * @summary Save a new content alert
   * @request POST:/Api/V1/ContentAlert
   * @secure
   */
  createAlertUsingPost = (request: BaseRequestOfContentAlertRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/ContentAlert`,
      method: "POST",
      body: request,
      secure: true,
      headers: headers,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags content-alert-controller
   * @name GetAllAlertUsingGet
   * @summary Get All Alerts
   * @request GET:/Api/V1/ContentAlert/All
   * @secure
   */
  getAllAlertUsingGet = (query?: { page?: number; size?: number; sortBy?: string }, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/ContentAlert/All`,
      method: "GET",
      query: query,
      secure: true,
      headers: headers,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags content-alert-controller
   * @name GetAlertUsingGet
   * @summary Get Content Alert
   * @request GET:/Api/V1/ContentAlert/{id}
   * @secure
   */
  getAlertUsingGet = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/ContentAlert/${id}`,
      method: "GET",
      secure: true,
      headers: headers,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags content-alert-controller
   * @name UpdateAlertUsingPut
   * @summary Update Comments
   * @request PUT:/Api/V1/ContentAlert/{id}
   * @secure
   */
  updateAlertUsingPut = (id: string, request: BaseRequestOfContentAlertCommentRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/ContentAlert/${id}`,
      method: "PUT",
      body: request,
      secure: true,
      headers: headers,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
 * No description
 *
 * @tags assets-controller
 * @name GetOneUsingGet1
 * @summary Get one
 * @request GET:/Api/V1/Resources/{id}
 * @secure
 */
  getOneUsingGet1 = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Resources/${id}`,
      method: "GET",
      secure: true,
      headers: headers,
      ...params,
    });


  /**
   * No description
   *
   * @tags spaces-controller
   * @name DeleteSpaceUsingDelete
   * @summary Delete space data
   * @request DELETE:/Api/V1/Spaces/{id}
   * @secure
   */
  deleteSpaceUsingDelete = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });

  /**
* No description
*
* @tags activities-controller
* @name DeleteResourceUsingDelete
* @summary Delete Resource
* @request DELETE:/Api/V1/Activities/{id}/Resource/{resourceId}
* @secure
*/
  deleteResourceUsingDelete = (id: string, resourceId: string, userId: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities/${id}/Resource/${resourceId}`,
      method: "DELETE",
      body: userId,
      secure: true,
      headers: headers,
      ...params,
    });


  /**
 * No description
 *
 * @tags activities-controller
 * @name DeleteActivityUsingDelete
 * @summary Delete activity data
 * @request DELETE:/Api/V1/Activities/{id}
 * @secure
 */
  deleteActivityUsingDelete = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Activities/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      headers: headers,
      ...params,
    });
  /**
 * No description
 *
 * @tags contacts-controller
 * @name DisableUsingPut
 * @summary Disable Contacts
 * @request PUT:/Api/V1/Contacts/{id}/Disable
 * @secure
 */
  disableUsingPut = (id: string, request: BaseRequestOfContactDisableRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Contacts/${id}/Disable`,
      method: "PUT",
      body: request,
      secure: true,
      headers: headers,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
 * No description
 *
 * @tags users-controller
 * @name ResetPasswordRequestUsingPost
 * @summary Reset user password request
 * @request POST:/Api/V1/Users/resetPassword
 * @secure
 */
  resetPasswordRequestUsingPost = (request: BaseRequestOfResetPasswordRequest, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Users/resetPassword`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
 * No description
 *
 * @tags users-controller
 * @name UserUpdatePasswordUsingPost
 * @summary userUpdatePassword
 * @request POST:/Api/V1/Users/resetPassword/{hash}/confirm
 * @secure
 */
  userUpdatePasswordUsingPost = (hash: string, request: BaseRequestOfUpdatePasswordUserRequest, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/Api/V1/Users/resetPassword/${hash}/confirm`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
 * No description
 *
 * @tags loads-controller
 * @name AllUsingGet3
 * @summary Get All
 * @request GET:/Api/V1/Loads
 * @secure
 */
  allUsingGet3 = (headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Loads`,
      method: "GET",
      secure: true,
      headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags loads-controller
   * @name CreateUsingPost1
   * @summary Create new Batch Load
   * @request POST:/Api/V1/Loads
   * @secure
   */
  createUsingPost1 = (data: CreateUsingPost1Payload, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Loads`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags loads-controller
   * @name GetOneUsingGet2
   * @summary Get one
   * @request GET:/Api/V1/Loads/{id}
   * @secure
   */
  getOneUsingGet2 = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Loads/${id}`,
      method: "GET",
      secure: true,
      headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags loads-controller
   * @name CancelUsingPut
   * @summary Cancel Batch Load
   * @request PUT:/Api/V1/Loads/{id}/Cancel
   * @secure
   */
  cancelUsingPut = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Loads/${id}/Cancel`,
      method: "PUT",
      secure: true,
      type: ContentType.Json,
      headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags loads-controller
   * @name StartUsingPut1
   * @summary Cancel Batch Load
   * @request PUT:/Api/V1/Loads/{id}/Start
   * @secure
   */
  startUsingPut1 = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Loads/${id}/Start`,
      method: "PUT",
      secure: true,
      type: ContentType.Json,
      headers,
      ...params,
    });


  /**
   * No description
   *
   * @tags system-roles-controller
   * @name GetAllUsingGet3
   * @summary getAll
   * @request GET:/Api/V1/Roles
   * @secure
   */
  getAllUsingGet5 = (
    query?: { orderType?: string; page?: number; size?: number; sortBy?: string },
    headers?: Record<string, string>, params: RequestParams = {},
  ) =>
    this.request<any, void>({
      path: `/Api/V1/Roles`,
      method: "GET",
      query: query,
      format: 'json',
      secure: true,
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags system-roles-controller
   * @name CreateUsingPost3
   * @summary Create
   * @request POST:/Api/V1/Roles
   * @secure
   */
  createUsingPost4 = (createRequest: BaseRequestOfCreateRole, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Roles`,
      method: "POST",
      body: createRequest,
      secure: true,
      headers: headers,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags system-roles-controller
   * @name GetOneUsingGet3
   * @summary getOne
   * @request GET:/Api/V1/Roles/{id}
   * @secure
   */
  getOneUsingGet4 = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/Api/V1/Roles/${id}`,
      method: "GET",
      secure: true,
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags system-roles-controller
   * @name UpdateUsingPut1
   * @summary update
   * @request PUT:/Api/V1/Roles/{id}
   * @secure
   */
  updateUsingPut1 = (id: string, roleRequest: BaseRequestOfUpdateRole, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/Api/V1/Roles/${id}`,
      method: "PUT",
      body: roleRequest,
      secure: true,
      headers: headers,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags system-roles-controller
   * @name DeleteUsingDelete4
   * @summary delete
   * @request DELETE:/Api/V1/Roles/{id}
   * @secure
   */
  deleteUsingDelete4 = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/Api/V1/Roles/${id}`,
      method: "DELETE",
      secure: true,
      headers: headers,
      ...params,
    });
  /**
   * No description
   *
   * @tags system-roles-controller
   * @name DisableUsingPut1
   * @summary disable
   * @request PUT:/Api/V1/Roles/{id}/Disable
   * @secure
   */
  disableUsingPut1 = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/Api/V1/Roles/${id}/Disable`,
      method: "PUT",
      secure: true,
      headers: headers,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags system-roles-controller
   * @name EnableUsingPut
   * @summary enable
   * @request PUT:/Api/V1/Roles/{id}/Enable
   * @secure
   */
  enableUsingPut = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/Api/V1/Roles/${id}/Enable`,
      method: "PUT",
      secure: true,
      headers: headers,
      type: ContentType.Json,
      ...params,
    });

  /**
   * No description
   *
   * @tags system-controller
   * @name GetAllUiGrantsUsingGet
   * @summary getAllUIGrants
   * @request GET:/Api/V1/System/UI-Grants
   * @secure
   */
  getAllUiGrantsUsingGet = (headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/Api/V1/System/UI-Grants`,
      method: "GET",
      secure: true,
      headers: headers,
      ...params,
    });

  /**
 * No description
 *
 * @tags users-controller
 * @name UserUpdateRoleUsingPut
 * @summary userUpdateRole
 * @request PUT:/Api/V1/Users/{id}/Role
 * @secure
 */
  userUpdateRoleUsingPut = (id: string, request: BaseRequestOfUpdateUserRoleRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/Api/V1/Users/${id}/Role`,
      method: "PUT",
      body: request,
      secure: true,
      headers: headers,
      type: ContentType.Json,
      ...params,
    });
  /**
 * No description
 *
 * @tags contacts-controller
 * @name DeleteRelatedUsingDelete
 * @summary Child Contacts
 * @request DELETE:/Api/V1/Contacts/{id}/Childs
 * @secure
 */
  deleteRelatedUsingDelete = (id: string, childContacts: BaseRequestOfContactsDeleteRelatedRequest, headers?: Record<string, string>, params: RequestParams = {},) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Contacts/${id}/Childs`,
      method: "DELETE",
      body: childContacts,
      secure: true,
      type: ContentType.Json,
      format: "json",
      headers,
      ...params,
    });
  /**
     * No description
     *
     * @tags cycles-controller
     * @name GetAllCyclesUsingGet
     * @summary Get all cycles
     * @request GET:/Api/V1/Cycles/
     * @secure
     */
  getAllCyclesUsingGet = (query?: { enabled?: boolean }, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Cycles/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      headers,
      ...params,
    });
  /**
  * No description
  *
  * @tags cycles-controller
  * @name CreateUsingPost2
  * @summary Crate Cycle
  * @request POST:/Api/V1/Cycles/
  * @secure
  */
  createCycleUsingPost2 = (request: BaseRequestOfCycleUpdateRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Cycles/`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      format: "json",
      headers,
      ...params,
    });
  /**
  * No description
  *
  * @tags cycles-controller
  * @name GetOpenCycleUsingGet
  * @summary Get open cycle
  * @request GET:/Api/V1/Cycles/getOpen
  * @secure
  */
  getOpenCycleUsingGet = (query?: { enabled?: boolean }, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Cycles/getOpen`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      headers,
      ...params,
    });
  /**
  * No description
  *
  * @tags cycles-controller
  * @name GetOpenCycleUsingGet
  * @summary Get open cycle
  * @request GET:/Api/V1/Cycles/getOpen
  * @secure
  */
  getOpenCycleBySpaceOrAssigment = (id: string, query?: { enabled?: boolean }, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Cycles/getOpenCycleBySpaceOrAssigment/${id}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      headers,
      ...params,
    });
  /**
  * No description
  *
  * @tags cycles-controller
  * @name GetAllUnitsUsingGet
  * @summary Get all units of cycle
  * @request GET:/Api/V1/Cycles/{cycleId}/Unit
  * @secure
  */
  getAllUnitsUsingGet = (cycleId: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Cycles/${cycleId}/Unit`,
      method: "GET",
      secure: true,
      format: "json",
      headers,
      ...params,
    });
  /**
  * No description
  *
  * @tags cycles-controller
  * @name CreateUnitUsingPost
  * @summary Crate Unit
  * @request POST:/Api/V1/Cycles/{cycleId}/Unit
  * @secure
  */
  createUnitUsingPost = (cycleId: string, request: BaseRequestOfCycleUnitRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Cycles/${cycleId}/Unit`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      format: "json",
      headers,
      ...params,
    });
  /**
  * No description
  *
  * @tags cycles-controller
  * @name UpdateUnitUsingPut
  * @summary updateUnit
  * @request PUT:/Api/V1/Cycles/{cycleId}/Unit/{id}
  * @secure
  */
  updateUnitUsingPut = (cycleId: string, id: string, request: BaseRequestOfCycleUnitRequest, headers?: Record<string, string>, params: RequestParams = {},) =>
    this.request<any, void>({
      path: `/Api/V1/Cycles/${cycleId}/Unit/${id}`,
      method: "PUT",
      body: request,
      secure: true,
      type: ContentType.Json,
      headers,
      ...params,
    });
  /**
  * No description
  *
  * @tags cycles-controller
  * @name DeleteUnitUsingDelete
  * @summary deleteUnit
  * @request DELETE:/Api/V1/Cycles/{cycleId}/Unit/{id}
  * @secure
  */
  deleteUnitUsingDelete = (cycleId: string, id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/Api/V1/Cycles/${cycleId}/Unit/${id}`,
      method: "DELETE",
      secure: true,
      headers,
      ...params,
    });
  /**
  * No description
  *
  * @tags cycles-controller
  * @name UpdateUsingPut1
  * @summary update
  * @request PUT:/Api/V1/Cycles/{id}
  * @secure
  */
  updateCycleUsingPut1 = (id: string, request: BaseRequestOfCycleUpdateRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/Api/V1/Cycles/${id}`,
      method: "PUT",
      body: request,
      secure: true,
      type: ContentType.Json,
      headers,
      ...params,
    });
  /**
 * No description
 *
 * @tags spaces-controller
 * @name GetAllScoreLoadsUsingGet
 * @summary Get All Score Loads
 * @request GET:/Api/V1/Spaces/{id}/ScoreLoads
 * @secure
 */
  getAllScoreLoadsUsingGet = (id: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${id}/ScoreLoads`,
      method: "GET",
      secure: true,
      headers,
      ...params,
    });
  /**
 * No description
 *
 * @tags spaces-controller
 * @name CreateUsingPost4
 * @summary Create Score Loads
 * @request POST:/Api/V1/Spaces/{spaceId}/ScoreLoad/Batch
 * @secure
 */
  createScoreLoadUsingPost = (spaceId: string, scoreLoadResquest: BaseRequestOfScoreLoadResquest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${spaceId}/ScoreLoad/Batch`,
      method: "POST",
      body: scoreLoadResquest,
      secure: true,
      type: ContentType.Json,
      headers,
      ...params,
    });
  /**
 * No description
 *
 * @tags spaces-controller
 * @name GetScoreLoadUsingGet
 * @summary Get Specific Score Load
 * @request GET:/Api/V1/Spaces/{spaceId}/ScoreLoads/{id}
 * @secure
 */
  getScoreLoadUsingGet = (spaceId: string, scoreId: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${spaceId}/ScoreLoads/${scoreId}`,
      method: "GET",
      secure: true,
      headers,
      ...params,
    });
  /**
 * No description
 *
 * @tags spaces-controller
 * @name DeleteScoreLoadUsingDelete
 * @summary Delete Score Loads
 * @request DELETE:/Api/V1/Spaces/{spaceId}/ScoreLoads/{id}
 * @secure
 */
  deleteScoreLoadUsingDelete = (spaceId: string, scoreId: string, scoreLoadDeleteResquestBaseRequest: BaseRequestOfScoreLoadDeleteResquest, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${spaceId}/ScoreLoads/${scoreId}`,
      method: "DELETE",
      body: scoreLoadDeleteResquestBaseRequest,
      secure: true,
      type: ContentType.Json,
      headers,
      ...params,
    });
  /**
 * No description
 *
 * @tags spaces-controller
 * @name GetScoreOfSpaceUsingGet
 * @summary Get Score of space
 * @request GET:/Api/V1/Spaces/{id}/Score
 * @secure
 */
  getScoreOfSpaceUsingGet = (spaceId: string, query: { contact_id?: string; unit_id?: string }, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${spaceId}/Score`,
      method: "GET",
      query: query,
      secure: true,
      headers,
      ...params,
    });
  /**
 * No description
 *
 * @tags spaces-controller
 * @name DownloadScoreLoadUsingGet
 * @summary Get All Score Load
 * @request GET:/Api/V1/Spaces/{spaceId}/ScoreLoads/{id}/Download
 * @secure
 */
  downloadScoreLoadUsingGet = (spaceId: string, scoreId: string, headers?: Record<string, string>, params: RequestParams = {}) =>
    this.request<BaseResponse, void>({
      path: `/Api/V1/Spaces/${spaceId}/ScoreLoads/${scoreId}/Download`,
      method: "GET",
      secure: true,
      headers,
      ...params,
    });

    getSpaceBreadCrumbGet = (spaceId: string, query: {}, headers?: Record<string, string>, params: RequestParams = {}) =>
        this.request<BaseResponse, void>({
            path: `/Api/V1/Spaces/${spaceId}/BreadCrumb`,
            method: "GET",
            query: query,
            secure: true,
            headers,
            ...params,
        });

    getContactsIndicatorsGet = (query: {}, headers?: Record<string, string>, params: RequestParams = {}) =>
        this.request<BaseResponse, void>({
            path: `/Api/V1/Contacts/Indicators`,
            method: "GET",
            query: query,
            secure: true,
            headers,
            ...params,
        });

    getCustomerBalance = (customerId: string, query: {}, headers?: Record<string, string>, params: RequestParams = {}) =>
        this.request<BaseResponse, void>({
            path: `/Api/V2/Customers/${customerId}/Balance`,
            method: "GET",
            query: query,
            secure: true,
            headers,
            ...params,
        });
    /**
     * Payment service
     * @param customerId
     * @param paymentRequest
     * @param headers
     * @param params
     */
    postPayment = (customerId: string, baseRequest: BaseRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
        this.request<BaseResponse, void>({
            path: `/Api/V2/Customers/${customerId}/Payments?pending=true`,
            method: "POST",
            body: baseRequest,
            secure: true,
            type: ContentType.Json,
            headers,
            ...params,
        });
    /**
     * Load pay file
     * @param customerId
     * @param id
     * @param data
     * @param headers
     * @param params
     */
    uploadPayImagePost = (customerId: string, id: string, data: UpdateContactAvatarUsingPutPayload, headers?: Record<string, string>, params: RequestParams = {}) =>
        this.request<BaseResponse, void>({
            path: `/Api/V2/Customers/${customerId}/Payments/${id}/Attachments`,
            method: "POST",
            body: data,
            secure: true,
            headers: headers,
            type: ContentType.FormData,
            format: "json",
            ...params,
        });

    /**
     * AI Service
     * @param data
     */
    suggestionsPost = (data: BaseRequest, headers?: Record<string, string>, params: RequestParams = {}) =>
        this.request<BaseRequest, void>({
            path: "/Api/V1/AI/Suggestions",
            method: 'POST',
            body: data,
            secure: true,
            headers: headers,
            type: ContentType.Json,
            format: "json",
            ...params
        })

}
