<app-ui-modal #modalSpotlightAI [dialogClass]="'modal-lg'">
    <div class="app-modal-body">
        <div class="form-group">
            <input type="text" class="form-control" id="course" name="course"
                   [placeholder]="label"
                   #prompt="ngModel"
                   [(ngModel)]="value"
                   (keydown.enter)="onLoadOptions()"
                   required>
        </div>
    </div>

</app-ui-modal>
<app-ui-modal #modalSpotlightOptions [dialogClass]="'modal-lg'">
    <div class="app-modal-body">
        <div class="form-group">
            <!-- Display radio button options -->
            <div *ngFor="let option of options" class="form-check option-item mt-4 mb-4">
                <input
                        type="radio"
                        class="form-check-input"
                        id="{{option}}"
                        name="course"
                        [value]="option"
                        [(ngModel)]="selectedOption"
                />
                <label class="form-check-label" for="{{option}}">{{ option }}</label>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button class="btn btn-primary" (click)="onSend()">Seleccionar</button>
    </div>
</app-ui-modal>
<div class="card-body d-flex flex-row justify-content-center mt-5">
    <div class="spinner-border text-info" role="status">
        <span class="sr-only"></span>
    </div>
</div>
<app-screen-loading #screenLoading [message]="message"></app-screen-loading>

