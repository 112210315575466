import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ItemViewComponent } from './itemView.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { ItemComponent } from './item/item.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertModule, BreadcrumbModule, CardModule, ModalModule } from '../../../theme/shared/components';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ChipAutoCompleteModule } from '../../../shared/chipAutoComplete/chipAutoComplete.module';
import {MatButtonModule} from '@angular/material/button';
import {SpotlightAIModule} from '../../../shared/spotlightAI/spotlightAI.module';


@NgModule({
    declarations: [ItemViewComponent, ItemComponent],
    exports: [
        ItemViewComponent,
        ItemComponent,
    ],
    imports: [
        CommonModule, ChipAutoCompleteModule,
        ReactiveFormsModule,
        NgbModule,
        BrowserAnimationsModule,
        FormsModule,
        MatFormFieldModule,
        MatChipsModule,
        MatCheckboxModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatIconModule,
        ModalModule,
        CardModule,
        BreadcrumbModule,
        AlertModule, MatButtonModule,
        SpotlightAIModule
    ],
})
export class ItemViewModule { }
