import {Component, Inject, Input, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter, OnInit} from '@angular/core';
import {UiModalComponent} from '../../theme/shared/components/modal/ui-modal/ui-modal.component';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {StorageService} from '../../../api/service/storage.service';
import {Service} from '../../../api/service/Service';
import {ScreenLoadingComponent} from '../screenLoading/screenLoading.component';

@Component({
    selector: "spolight-ai",
    templateUrl: "./spotlightAI.component.html",
    styleUrls: ["./spotlightAI.component.scss"],
})
export class SpotlightAIComponent implements OnInit, OnChanges{
    selectedOption: string;
    options: string[];
    message: "Cargando"
    @Input() open: boolean;
    @Input() label: string;
    @Output() valueSubmitted = new EventEmitter<string>();
    @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
    value: string;
    @ViewChild("modalSpotlightAI") promptModal: UiModalComponent;
    @ViewChild("modalSpotlightOptions") optionsModal: UiModalComponent;
    @ViewChild("screenLoading") screenLoading: ScreenLoadingComponent;

    constructor(
        private toastyService: ToastyService,
        private storageService: StorageService,
    ) {
    }

    ngOnInit(): void {
        this.value = "";
        this.selectedOption = null;
    }


    ngOnChanges(changes: SimpleChanges): void {
        if(changes.open && changes.open.currentValue === true){
            this.promptModal.show();
        }
        if(changes.open && changes.open.currentValue === false){
            this.promptModal.hide();
        }
    }

    onLoadOptions(): void {
        this.getSuggestions();
    }

    onSend(): void {
        this.valueSubmitted.emit(this.selectedOption);
        this.optionsModal.hide();
    }

    ngAfterViewInit() {
        this.promptModal.onHide.subscribe(() => {
            this.value = "";
            this.closeModal.emit();
        });
    }

    getSuggestions():void {
        const service = new Service(this.storageService);
        this.screenLoading.on();
        service.suggestions({prompt: this.value})
            .then(value => {
                try{
                    this.promptModal.hide();
                    this.options = JSON.parse(value);
                    this.optionsModal.show();
                }catch(err){
                    console.error(err)
                    this.toastyService.clearAll();
                    const toastOptions: ToastOptions = {
                        title: "Error",
                        msg: err.message,
                        timeout: 5000,
                        theme: "default"
                    };
                    this.toastyService.error(toastOptions);
                }

            })
            .catch(err => {
                this.toastyService.clearAll();
                const toastOptions: ToastOptions = {
                    title: "Error",
                    msg: err.message,
                    timeout: 5000,
                    theme: "default"
                };
                this.toastyService.error(toastOptions);
            })
            .finally(() => {
                this.screenLoading.off()
            });
    }



}
